body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container-habi {
  margin: 0 16px;
  min-width: 288px;
  max-width: 712px;
}

html {
  scroll-behavior: smooth;
}

@media screen and (min-width: 744px) {
  .container-habi {
    margin: 0 48px;
    min-width: 648px;
    max-width: 1264px;
  }
}

@media screen and (min-width: 1360px) {
  .container-habi {
    margin: 0 80px;
    min-width: 1200px;
    max-width: 1280px;
  }
}

@media screen and (min-width: 1440px) {
  .container-habi {
    margin: 0 auto;
    min-width: auto;
  }
}
@font-face {
  font-family: "Open Sans";
  src: url("static/assets/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans Bold";
  src: url("static/assets/fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans ExtraBold";
  src: url("static/assets/fonts/OpenSans-ExtraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans Light";
  src: url("static/assets/fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans SemiBold";
  src: url("static/assets/fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/*Muli*/

@font-face {
  font-family: "Muli";
  src: url("static/assets/fonts/Muli-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Muli Bold";
  src: url("static/assets/fonts/Muli-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Muli Black";
  src: url("static/assets/fonts/Muli-Black.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Muli ExtraBold";
  src: url("static/assets/fonts/Muli-ExtraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Muli Light";
  src: url("static/assets/fonts/Muli-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Muli SemiBold";
  src: url("static/assets/fonts/Muli-SemiBold.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
